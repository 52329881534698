@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300"); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300");

:where(.css-dev-only-do-not-override-8si27q).ant-tabs-card
  > .ant-tabs-nav
  .ant-tabs-tab,
:where(.css-dev-only-do-not-override-8si27q).ant-tabs-card
  > div
  > .ant-tabs-nav
  .ant-tabs-tab {
  border: none !important;
}

div {
  font-family: "Inter", "Comfortaa", sans-serif;
}

th {
  background-color: #2c2c2c;
}
